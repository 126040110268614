.page-home img {
    width: 100%;
}

#sc_banner>.container {
    height: 100vh;
}
.view-sm h4 {
    font-size: 21px;
    color: #6d381d;
}

.view-sm button {
    color: #6d381d;
    background: transparent;
    border: 2px solid #6d381d;
    margin-bottom: 20px;
    border-radius: 3px;
}
#sc_banner {
    min-height: 100vh;
    background: url('../../../public/images/btbn.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}

#sc_presale {
    min-height: 100vh;
    background: url('../../../public/images/45yh.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.box-presale {
    background: url('../../../public/images/bg-presale.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px 80px;
}

.box-countdowntime {
    display: inline-block;
    background: #fff;
    border-radius: 10px;
    padding: 5px 5px;
}

.box-countdowntime span.item-time {
    padding: 10px 20px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
}

.box-countdowntime span.item-time span.time-text {
    display: block;
    font-size: 14px;
    color: #9f796a;
    line-height: 15px;
}

.box-countdowntime span.item-time span.time-t {
    font-size: 28px;
    line-height: 20px;
    color: #39a657;
    font-weight: bold;
}

span.item-time-line {
    font-size: 34px;
    font-weight: bold;
    position: relative;
    top: -11px;
}

.tokens-sold-line {
    display: block;
    background: #fff;
    border-radius: 20px;
    position: relative;
    height: 24px;
}

.tokens-sold-sell {
    background: #39a657;
    display: inline-block;
    height: 24px;
    position: absolute;
    left: 0px;
    border-radius: 20px;
}

.tokens-sold-line span {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.tokens-sold>p {
    text-align: left;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #684638;
}

.tokens-sold-stage {
    color: #684638;
    font-size: 16px;
    margin-top: 7px;
    font-weight: bold;
    text-align: left;
}

.tokens-sold-stage span {
    color: #FF5722;
}

.gr-ip-payment {
    position: relative;
}

.gr-ip-payment label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #684638;
    font-weight: bold;
}

.gr-ip-payment button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.gr-ip-payment input {
    width: 100%;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #684638;
    padding: 5px 10px;
    margin-bottom: 16px;
    font-weight: bold;
    color: #000;
}

button.btn-payment-max {
    border: 1px solid #000;
    border-radius: 3px;
    background: transparent;
    font-size: 14px;
}

.coin-rate {
    width: 100%;
    text-align: center !important;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.coin-rate p {
    position: relative;
}

.coin-rate p:before {
    content: "";
    width: 100%;
    height: 2px;
    background: #684638;
    position: absolute;
    left: 0px;
    top: calc(50% - 1px);
    z-index: 1;
}

.coin-rate p span {
    z-index: 10;
    background: #684638;
    display: inline-block;
    padding: 4px 20px;
    position: relative;
    opacity: 1 !important;
    border-radius: 3px;
    color: #fff;
}

.type-pay button {
    width: calc(50% - 5px);
    font-weight: bold;
    height: 40px;
    border-radius: 4px;
    border: 2px solid #674539;
    color: #674539;
    background: #fff;
}

button.btn-payment-bnb {
    margin-right: 10px;
}

.type-pay button.active {
    background: #3dab5e;
    color: #fff;
    border-color: #41b064;
}

.total-wallet {
    text-align: left;
    margin-top: 15px;
    font-weight: bold;
    color: #684638;
}

.gr-btn-payment button {
    min-width: 200px;
    background: #39a657;
    border: 1px solid #39a657;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}

.gr-btn-payment {
    padding-bottom: 25px;
    padding-top: 20px;
}

.height100 {
    height: 100vh;
}

div#sc_presale {
    padding: 100px 0px;
}

div#sc_about {
    min-height: 100vh;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
}

#bg_about {}

div#sc_about h2 {
    font-size: 64px;
    margin-bottom: 60px;
    color: #3dab5e;
    font-weight: bold;
}

.title-about {
    position: relative;
}

.title-about:before {
    content: "";
    width: 100px;
    height: 140px;
    background: url('../../../public/images/i-ab.png');
    background-size: 100%;
    position: absolute;
    z-index: 9;
    left: -44px;
    top: -32px;
}

div#sc_about p {
    font-size: 22px;
}

.icon-about {
    position: absolute;
    right: 20%;
    bottom: 10%;
    animation: floatCloud2 10s linear infinite;
}

.icon-about img.iabout2 {
    width: 100%;
    max-width: 220px;
}

.icon-about img.iabout1 {
    width: 100%;
    max-width: 150px;
    position: absolute;
    bottom: 67px;
    right: 30px;
}

.icon-about .box-icon-about {
    position: relative;
}

img.iabout3 {
    position: absolute;
    width: 135px;
    bottom: 60%;
    right: 10%;
    animation: floatCloud1 6s linear infinite;
}

img.iabout4 {
    position: absolute;
    width: 267px;
    bottom: 30%;
    left: 10%;
    animation: floatCloud 4s linear infinite;
}

#sc_roadmap {
    background: url('../../../public/images/3d-fantasy-scen.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

div#bg_roadmap {
    background: rgb(0 0 0 / 60%);
    padding-top: 100px;
    padding-bottom: 100px;
    color: #fff;
    overflow: hidden;
}

div#sc_tokenomics {
    padding-top: 100px;
    padding-bottom: 100px;
}

.item-tokenomics-img {
    height: 220px;
    text-align: center;
}

.item-tokenomics-img img {
    height: 100%;
    width: auto;
}

.item-tokenomics-main {
    text-align: center;
    margin-top: 10px;
}

.item-tokenomics-main h3 {
    color: rgb(112, 53, 25);
    font-size: 22px;
    font-weight: bold;
}

.item-tokenomics-main h4 {
    color: #cd400c;
    font-size: 26px;
    font-weight: bold;
}


div#sc_tokenomics h2 {
    font-size: 64px;
    margin-bottom: 30px;
}

.main-timeline {
    font-family: 'Poppins', sans-serif;
    padding: 25px 10px;
}

.main-timeline:after {
    content: '';
    display: block;
    clear: both;
}



.main-timeline .title {
    color: #3dab5e;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px;
}

.main-timeline .description {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #000;
}

.wapper-bg {
    background: #E0DCC2;
}

.timeline-content {
    background: url('../../../public/images/bg7.png');
    background-size: 100% 100%;
    padding: 40px 70px !important;
    width: 50%;
    margin-bottom: 50px;
    position: relative;
}

.main-timeline .timeline:nth-child(even) .timeline-content {
    margin-left: 50%;
}

.main-timeline .timeline:nth-child(2) .timeline-content {
    width: 60%;
    margin-top: 30px;
}

.main-timeline .timeline:nth-child(3) .timeline-content {
    width: 60%;
    margin-top: 30px;
    margin-left: 40px;
}

.main-timeline .timeline:nth-child(2) .timeline-content {
    width: 60%;
    margin-top: 30px;
}

.main-timeline .timeline:nth-child(3) .timeline-content {
    width: 60%;
    margin-top: 30px;
    margin-left: 40px;
}

.main-timeline .timeline:nth-child(5) .timeline-content {
    width: 60%;
}

.timeline-icon {
    position: absolute;
    top: -20px;
}

.timeline-icon img {
    max-width: 100px;
}

.main-timeline .timeline:nth-child(5) .timeline-content {
    width: 60%;
}

div#sc_roadmap .title-h {
    font-size: 64px;
    margin-bottom: 80px;
    margin-top: 100px;
    position: relative;
}

div#sc_roadmap .title-h span {
    position: relative;
}

div#sc_roadmap .title-h span:before {
    content: "";
    background: url('../../../public/images/k1.png');
    position: absolute;
    width: 72px;
    height: 94px;
    z-index: 999;
    background-size: 100%;
    top: -7px;
    background-repeat: no-repeat;
    left: -75px;

}

.item-team div {
    min-height: 96px;
}





@-webkit-keyframes cloud {

    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -100%;
    }

}

@-webkit-keyframes cat {

    0% {
        margin-top: 100%;
    }

    100% {
        margin-top: -100%;
        margin-left: 100%;
    }

}

@keyframes fly {
    0% {
        transform: translate(0, -50%);
        left: -100px;
    }

    100% {
        transform: translate(calc(100vw + 100px), -50%);
        left: calc(100vw + 100px);
    }
}

@keyframes move {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(100vh * random(-0.5, 0.5)));
    }
}

.height100 {
    height: 100%;
}

.bn-img img {
    animation: circleScale 12s infinite;
}

@keyframes circleScale {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

div#sc_banner h1 {
    color: #fff;
    font-size: 65px;
    font-weight: bold;
    margin-bottom: 25px;
}

div#sc_banner h5 {
    color: #3dab5e;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;
}

div#sc_banner p {
    font-size: 22px;
    color: #fff;
}

p.presale-text1 {
    font-weight: bold;
    font-size: 18px;
    color: #684638;
}

.box-presale h2 {
    font-weight: bold;
    text-align: center;
    color: rgb(112, 53, 25);
    margin-top: 20px;

}

.main-timeline .timeline:nth-child(1) .timeline-content:after {
    content: '';
    position: absolute;
    width: 93px;
    height: 163px;
    background: url('../../../public/images/m1.png');
    background-repeat: no-repeat;
    top: calc(100% - 5px);
    right: -13px;
    background-size: 100% 100%;
}

.main-timeline .timeline:nth-child(2) .timeline-content:after {
    content: '';
    position: absolute;
    width: 81px;
    height: 142px;
    background: url('../../../public/images/m2.png');
    background-repeat: no-repeat;
    top: calc(100% - 35px);
    left: -31px;
    background-size: 100% 100%;
}


#sc_tokenomics {
    background: url('../../../public/images/wood-background-light.jpg');
    background-size: 100%;
    min-height: 100vh;
    background-position: center;
}

.item-tokenomics {
    background: url('../../../public/images/paper.png');
    background-size: 100% 100%;
    padding-bottom: 30px;
    margin-bottom: 50px;
}
.tokenomics-desc {
    font-size: 24px;
    color: #fff;
    margin-bottom: 70px;
    text-align: center;
}
.item-tokenomics-img span {
   
    line-height: 150px;
    display: inline-block;
    width: 150px;
    margin-top: 42px;
    font-size: 40px;
    border-radius: 100%;
    font-weight: bold;
}

.item-tokenomics1 .item-tokenomics-img span {
    background: url('../../../public/images/btn1.png');
}
.item-tokenomics2 .item-tokenomics-img span {
    background: url('../../../public/images/btn2.png');
}
.item-tokenomics3 .item-tokenomics-img span {
    background: url('../../../public/images/btn3.png');
}
.item-tokenomics4 .item-tokenomics-img span {
    background: url('../../../public/images/btn4.png');
}
.item-tokenomics5 .item-tokenomics-img span {
    background: url('../../../public/images/btn5.png');
}
.item-tokenomics6 .item-tokenomics-img span {
    background: url('../../../public/images/btn6.png');
}
div#sc_tokenomics h2 {
    color: #fff;
    font-weight: bold;
    font-size: 64px;
}


@keyframes floatCloud {

    0%,
    100% {
        transform: translateY(50px);
    }

    50% {
        transform: translateY(30px);
    }
}

@keyframes floatCloud1 {

    0%,
    100% {
        transform: translateY(60px);
    }

    50% {
        transform: translateY(30px);
    }
}

@keyframes floatCloud2 {

    0%,
    100% {
        transform: translateX(-70px);
    }

    50% {
        transform: translateY(-30px);
    }
}

div#sc_roadmap .title-h span:before {
    animation: shake 8s cubic-bezier(.36, .07, .19, .97) infinite;
}

@keyframes shake {

    0%,
    100% {
        transform: translateY(0) rotate(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateY(-5px) rotate(-5deg);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateY(5px) rotate(5deg);
    }
}

footer.home.py-3.my-4 {
    background: #1f1824;
    margin: 0px !important;
    color: #fff;
}

footer p,
footer a {
    color: #fff !important;
}

footer a {
    font-size: 20px !important;
}

.logo-footer .logo {
    width: 120px;
    margin: 0px;
}

.logo-footer {
    text-align: center;
    padding: 20px 0px;
}

.btn-buy-now-a {
    background: #F44336;
    border: 1px solid #F44336;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    margin-top: 20px;
    animation: shake2 2s infinite;
    text-decoration: unset;
}

@keyframes shake2 {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px) rotate(-5deg);
    }

    50% {
        transform: translateX(5px) rotate(5deg);
    }

    75% {
        transform: translateX(-5px) rotate(-5deg);
    }

    100% {
        transform: translateX(0);
    }
}

.txt-tb {
    color: #f3450e;
    font-size: 22px;
}

#sc_team {
    background: url('../../../public/images/3d-fantasy-scene.jpg');
    min-height: 100vh;
    background-size: 100% 100%;
    position: relative;
}

#sc_team:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    /* background: linear-gradient(rgb(0 0 0 / 7%),rgb(0 0 0 / 40%)); */
    z-index: 2;
}

#sc_team_op {
    position: relative;
    z-index: 10;
    padding-top: 120px;
    padding-bottom: 120px;
}

.title-team span {
    background: url('../../../public/images/title_team.png');
    background-size: 100% 100%;
    color: #fff;
    display: inline-block;
    padding: 15px 50px;
    font-size: 54px;
    margin-bottom: 60px;
}

.item-team {
    background: url('../../../public/images/item_team.png');
    background-size: 100% 100%;
    padding: 60px 38px 100px;
    position: relative;
}

.item-team div {
    text-align: center;
    color: rgb(112, 53, 25);
    font-weight: 500;
}

img.avatar-team {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 100%;
    margin-bottom: 12px;
}

.item-team h4 {
    color: rgb(112, 53, 25);
    font-size: 25px;
    font-weight: bold;
}

.item-team span {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    font-size: 15px;
}

.icon-team {
    position: absolute;
    width: 100px !important;
    left: 0px;
    top: -20px;
}

#sc_partners {
    background: url('../../../public/images/wood-background-dark.jpg');
    background-size: 100% 100%;
    position: relative;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
}
.box-partners-main{
    width: 93.3333%;
    margin: auto;
}
.box-partners-content{
    background: linear-gradient(rgb(103, 19, 19) 0%, rgb(128, 31, 26) 100%);
    border-radius: 0px 0px 60px 60px;
    padding: 100px;   
    padding-top: 0px;
}
.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 50px;
}
.item-partner{
    background: url('../../../public/images/item_presale.png');
    background-size: 100% 100%;
    position: relative;  
    padding: 30px 20px;
}
.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 25px;
}

.item-partner-img {
    height: 130px;
}

.item-partner-img img {
    height: 100%;
    width: auto;
}

.item-partner h4 {
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold;
    margin-bottom: 20px;
}
h3.txt-tb2 {
    font-size: 22px;
    color: #fff;
}
.box-partners-content h2 {
    font-size: 64px;
    color: #fff;
    margin-bottom: 60px;
}
#sc_saleRound{
    min-height: 100vh;
    background: linear-gradient(rgb(103, 19, 19) 0%, rgb(128, 31, 26) 100%);
    padding-top: 150px;
    padding-bottom: 120px;
    color: #fff;
}
div#sc_saleRound h2 {
    font-size: 64px;
    margin-bottom: 50px;
    text-transform: capitalize;
}
.item-saleround{
    background: url('../../../public/images/bg9.png');
    background-size: 100% 100%;
}
.item-saleround-img {
    text-align: center;
    margin-bottom: 10px;
}

.item-saleround {
    padding: 120px 60px 140px;
    text-align: center;
    color: rgb(112, 53, 25);
}

.item-saleround-img img {
    max-width: 120px;
}

button.btn-sendcoin {
    background: #FF5722;
    color: #fff;
    border-radius: 3px;
    border: 0px;
    padding: 7px 11px;
}
.item-saleround-main p {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 8px;
    color: rgb(140 70 37);
}
.view-sm button {
    overflow-wrap: break-word;
    width: 100%;
}
.item-saleround-main h3 {
    color: rgb(100 41 14);
    font-weight: bold;
    min-height: 74px;
}
.item-saleround-main{
    min-height: 192px;
}
.nft-desc {
    font-size: 22px;
    margin-bottom: 80px;
}
div#sc_game {   
    background: url('../../../public/images/bg-game.jpg');
    background-size: 100%;
}

a.btn-play-game {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    font-size: 22px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    background-image: linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%);
}

div#sc_game p {
    font-size: 17px;
}
a.btn-play-game {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    font-size: 22px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    background-image: linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%);
}

div#sc_game p {
    font-size: 17px;
    color: #fff;
    font-weight: bold;
}

div#sc_game h5 {
    color: #F44336;
    font-size: 36px;
}

div#sc_game h1 {
    color: #4CAF50;
    font-size: 60px;
    margin-bottom: 30px;
    font-weight: bold;
}

div#sc_game > div {
    background: rgb(0 0 0 / 60%);
    padding: 140px 0px;
}
.box-img-game {
    display: table;
    height: 100%;
}

.bl-img-game {
    display: table-cell;
    vertical-align: middle; /* Center vertically */
    text-align: center;
    position: relative;
    padding-left: 30px;
    padding-top: 50px;
}
.blc-img-game {
    position: relative;
}

.blc-img-game:before {
    content: "";
    width: 60%;
    height: 10px;
    top: -9px;
    right: 0px;
    background: linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%);
    z-index: 99;
    position: absolute;
}
.blc-img-game:after {
    content: "";
    width: 10px;
    height: 80%;
    top: -9px;
    right: -10px;
    background: linear-gradient(180deg, #FF512F 0%, #DD2476  51%, #FF512F  100%);
    z-index: 99;
    position: absolute;
}
#sc_sale{
    background: url('../../../public/images/bg-sale.jpg');
    background-size: 100%;
}
.list-sale {
    display: grid;
    gap: 45px 45px;
    grid-template-columns: calc(33.33% - 30px) calc(33.33% - 30px) calc(33.33% - 30px);
}

.item-sale-img img {
    max-width: 80px;
}

.item-sale-img {
    text-align: center;
}

div#bg_sale {
    padding: 150px 0px;
    background: rgb(0 0 0 / 53%);
}

.item-sale {
    border: 3px solid #FF9800;
    border-radius: 8px;
    background: #ebddad;
    text-align: center;
    padding: 20px 0px 0px;
}
.item-sale-btn {
    background: #fa9c2e;
    padding: 12px 20px;
    margin-top: 20px;
}

.item-sale-img.item-sale-img-l img {
    min-width: 100px;
}
.item-sale-img {
    min-height: 100px;
}
.item-sale-img.item-sale-img-m img {
    max-width: 70px;
}
a.btn-buy-sale {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background: linear-gradient(to bottom, #bde455, #48ae33);
    border-radius: 14px;
    padding: 4px 30px;
    min-width: 130px;
}

.item-sale h4 {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: -1px -1px 0 #f6a041, 1px -1px 0 #f6a041, -1px 1px 0 #f6a041, 1px 1px 0 #f6a041;
}
div#sc_sale p {
    color: #f6a041;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
    max-width: 800px;
    margin: auto;
    margin-bottom: 60px;
}

div#sc_sale h2 {
    color: #f6a041;
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;
    text-shadow: 0px 2px 0px #ffffff;
}
@media(max-width:767px) {
    .list-sale {
        grid-template-columns: auto;
    }
    .bl-img-game {
        padding-left: 0px;
    }
    .tabc {
        overflow-y: auto;
    }
    header .navbar a.nav-link{
        color: #fff !important;
    }
    header .navbar-toggler {
        background: #fff !important;
    }
    .navbar-toggler{
        color:#fff;
    }
    header.viewPage,
    header.viewPage nav.navbar ,
    header.home nav.navbar {
        background: #2f363e !important;
    }
    header.home > nav {
        background: transparent !important;
    }
    
    div#sc_banner {
        padding-top: 130px;
    }
    
    a.navbar-brand .logo {
        margin: 0px !important;
        margin-left: 60px !important;
    }
    
    a.navbar-brand {
        text-align: center;
        margin: 0px;
    }
    
    header a.nav-link {
        padding-right: 0px !important;
    }
    .box-partners-content {
        padding: 40px 20px 100px;
    }
    
    .box-partners-content h2 {
        font-size: 28px;
    }
    
    .list-partner {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .box-presale {
        padding: 50px 35px;
    }

    .box-countdowntime span.item-time {
        font-size: 14px;
        padding: 10px 10px;
    }

    div#sc_about h2 {
        font-size: 42px;
        padding-top: 100px;
    }

    .title-about:before {
        top: 2px;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .box-presale {
        padding: 50px 35px;
    }

    .box-countdowntime span.item-time {
        font-size: 14px;
        padding: 10px 10px;
    }

    div#sc_about h2 {
        font-size: 42px;
        padding-top: 100px;
    }

    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    div#sc_about {
        padding-bottom: 200px;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .box-presale {
        padding: 50px 35px;
    }

    .box-countdowntime span.item-time {
        font-size: 14px;
        padding: 10px 10px;
    }

    div#sc_about h2 {
        font-size: 42px;
        padding-top: 100px;
    }

    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    div#sc_about {
        padding-bottom: 200px;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    div#sc_roadmap .title-h {
        font-size: 40px;
        margin-top: 0px;
    }

    .timeline-content {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .timeline-content {}

    .timeline-icon {
        left: 0px;
    }

    .main-timeline .timeline:nth-child(1) .timeline-content:after {
        right: 30px;
    }

  

    .main-timeline .timeline:nth-child(6) .timeline-content:after {
        right: calc(100% + -170px);
        top: calc(100% - 18px);
    }

    .item-tokenomics {
        margin-bottom: 30px;
    }

    div#sc_banner h1 {
        font-size: 44px;
    }

    header.home.p-3.text-bg-white {
        position: relative;
    }

    .could5 {
        width: 220px;
        height: 40px;
    }

    .could3 {
        width: 200px;
        height: 40px;
    }

    #sc_tokenomics,
    #sc_roadmap,
    #sc_presale {
        background-size: cover;
    }
}