body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  position: fixed;
  width: 100%;
  top: 0px;  
  border-bottom: 1px solid #fff;
  z-index: 999999;
  padding: 0px !important;
}
header a.nav-link{
  color: #000;
  font-weight: bold;
  padding-right: 25px !important;
}
.Toastify__toast-container--top-right {
  z-index: 9999999;
}
img.logo { 
  width: 70px;
  margin-right: 40px;
}
header.home > nav {
  background: rgb(255 255 255 / 19%) !important;
}